import {
    Table,
    Tbody,
    Text,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    Tr,
    ModalBody,
    ModalCloseButton,
    useColorModeValue,
    Box,
    Thead,
    Td,
    Th,
    useColorMode,
    Image,
    Spinner,
  } from "@chakra-ui/react";
import axios from "axios";
  import React, { useEffect, useRef, useState } from "react";
import { parseMultilingualText } from "utils";
  
  export default function TagModal(props) {
    const { isOpen, onClose, tagInput } = props;
    const initialRef = React.useRef(null);
    const finalRef = React.useRef(null);

    const textColor = useColorModeValue("gray.700", "white");


    const [productPic, setProductPic] = useState();

  const [tag, setTag] = useState();
  const [scans, setTotalScans] = useState([]);
  const [flag, setFlag] = useState(0);
  const [distributor, setDistributor]=useState(undefined)
  const [found, setFound] = useState(false);
  const [loading, setLoading] = useState(false);
 


  const flattenObject = (object) => {
    const ipAddress = object.ipAddress;
    const location = object.location;
    const createdAt = object.createdAt;

    const result = [];

    for (let i = 0; i < ipAddress.length; i++) {
      const flattenedObject = {
        ipAddress: ipAddress[i],
        location: location[i],
        companyId: object.companyId,
        createdAt: Array.isArray(createdAt[i]) ? createdAt[i][0] : createdAt[i],
        id: object.id,
        tagId: object.tagId,
        productId: object.productId,
        distributorId: object.distributorId,
        batchId: object.batchId,

      };


      result.push(flattenedObject);
    }

    return result;
  };

/*   useEffect(() => {
    console.log("close")
    const handleOutsideClick = (event) => {
      if (boxRef.current && !boxRef.current.contains(event.target)) {
        console.log("close")
        closeModal();
      }
    };

    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []); */





  useEffect(() => {
    setLoading(true);

    const getTag = async () => {

 await  axios
        .get(`/tag?tag=${tagInput}`)
        .then(async (res) => {
          setTag(res.data);




   await  axios
            .get(
              `/scan?tag=${tagInput}&company=${res.data.tag.companyId}&getAllTagScans=true`
            )
            .then(async (res) => {
                if(Object.keys(res.data).length != 0){
              var result = [];
             setFlag(res.data.flag ? res.data.flag : 0)
              var data = flattenObject(res.data);
              result.push(data);
              setTotalScans([...new Set(result.flat())]);
       
                }
                setFound(true);
              setLoading(false);
                

            })
            .catch((e) => {
              setFound(false);
              setLoading(false);
            });


           await axios
            .get(`/distributor?distributorId=${res.data?.distributor}&companyId=${res.data.tag?.companyId}`)
            .then((r) => {
              if (r.data) {
      
                setDistributor(r.data.name);
              }
            })


        
          

      
        })
        .catch((e) => {
          setFound(false);
          setLoading(false);
        });
      }


     
    

   
    getTag();
  }, [tagInput]);

  useEffect(() => {
if(tag && tag.product.productId){

   axios
           .get(`/s3?key=troofmedia/${tag?.product?.pictures[0]}`)
           .then((res) => {
             setProductPic(res.data);
           }).catch((e) => {
     
           });
       
        }
    },[tag]) 
    return (
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
        size={"3xl"}
      >
        <ModalOverlay />
        <ModalContent>
    
          <ModalHeader>Tag Details: {tagInput}</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6} >

          {tag && found && !loading && <> <div className="tag-profile-card">
      <div className="tag-profile-picture">
     {productPic && <Image
                src={`data:image/jpeg;base64,${productPic}`}
                boxSize="200px"
                objectFit="center"
                borderRadius="md"
                mr={4}
              />}
      </div>
      <div className="profile-details" style={{marginTop: "20px", marginBottom:"20px"}}>

        <div className="profile-detail">
          <span className="detail-label">Creation Date : </span>
          <span className="detail-value">{tag.tag?.createdAt }</span>
        </div>
        <div className="profile-detail">
          <span className="detail-label">Product : </span>
          <span className="detail-value">{ parseMultilingualText( tag?.product?.name) ? parseMultilingualText( tag?.product?.name) : "unknown"}</span>
        </div>
        <div className="profile-detail">
          <span className="detail-label">Distributor : </span>
          <span className="detail-value">{ distributor ?   distributor : "unknown"}</span>
        </div>
        <div className="profile-detail">
          <span className="detail-label">Batch ID : </span>
          <span className="detail-value">{tag?.batchId ?   tag?.batchId : "unknown" }</span>
        </div>
        <div className="profile-detail">
          <span className="detail-label">Flag : </span>
          <span className="detail-value">{  flag == 0 
              ? "red"
              :  flag == 2
              ? "gray"
              : "yellow"}</span>
        </div>
      </div>
    </div>

  <h2 style={{fontWeight: 'bold'}}>All Scans</h2>
            <Table
              variant="simple"
              color={textColor}
              style={{ marginTop: "10px" }}
            >
              <Thead>
                <Tr my=".8rem" pl="0px" color="gray.400">
                  <Th color="gray.500">Country</Th>
                  <Th color="gray.500">City</Th>
                  <Th color="gray.500">Timestamp</Th>
                  <Th color="gray.500">IP</Th>
                </Tr>
              </Thead>
              <Tbody>
                {scans &&
                  scans.length > 0 &&
                  scans.map((item, index) => {
                    return (
                      <Tr key={index}>
                        <Td>{item.location.country}</Td>
                        <Td>{item.location.city}</Td>
                        <Td>
                          {item.date ? item.date : item.createdAt}
                        </Td>
                        <Td>{item.ipAddress}</Td>
                      </Tr>
                    );
                  })}
                  {scans.length==0 && found && !loading && <div style={{ margin:"7px"}}>{"No scans yet..."}</div>}
              </Tbody>
            </Table> </>}
            {!found && !loading && <>OOPS TAG NOT FOUND</>}
{loading && <div style={{alignItems:"center", justifyContent:"center", display:"flex"}}><Spinner size="xl" color="gray"/></div>}
          </ModalBody>
        </ModalContent> 
      </Modal>
    );
  }
  