// Chakra imports
import {
  Flex,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
  //   useToast,
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";

import "./map.css";
import React, { useEffect, useState } from "react";
import GoogleMap from "google-map-react";
import axios from "axios";
import useAuth from "views/Pages/useAuth";
import ScanModal from "components/modals/scanModal";
import MapMarker from "components/MapMarker";
//import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

import { countriesCoordinates } from "variables/map";
function ScansDashboard(props) {
  const toast = useToast();

  const showToast = ({ status, title, description }) => {
    toast({
      title: title,
      description: description,
      status: status,
      duration: 5000,
      isClosable: true,
      position: "top-right",
    });
  };

  const textColor = useColorModeValue("gray.700", "white");
  const [showLocationInfo, setInfo] = useState(null);
  const [countries, setCountries] = useState([]);
  const [scans, setScans] = useState([]);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { company } = useAuth();
  const [currentZoom, setCurrentZoom] = useState(2);
  const [currentCenter, setCurrentCenter] = useState([0, 0]);
  const [currentPosition, setCurrentPosition] = useState(null);
  const [tagStatus, setStatusTag] = useState({});


  function handleClickInfo(index) {
    if (index == showLocationInfo) {
      setInfo(null);
    } else {
      setInfo(index);
    }
  }

  function changeMapInfo(e) {
    setCurrentZoom(e.zoom);

    if(e.zoom<6){

    setCurrentPosition(null)
    }
  }

  function fetchCountryScans(index) {
   // setCurrentZoom(6);
    setCurrentCenter(countries[index].coordinates);
  }

  function openCityScansModal(e, city, country, lat, long) {
    setSelectedCity(city);
    setSelectedCountry(country);
    setCurrentPosition({"latitude":lat, "longitude":long})
    
    onOpen();
  }

  function openCountryScansModal( country) {
    setSelectedCountry(country);
    setCurrentPosition(null)
    onOpen();

  }

  const createLocationResult = (city = '', country = '') => ({ city, country });

  // Initialize cache from localStorage
  const [locationCache, setLocationCache] = useState(() => {
    const cachedData = localStorage.getItem('locationCache');
    if (cachedData) {
      // Convert array of [key, {city, country}] back to Map
      return new Map(JSON.parse(cachedData));
    }
    return new Map();
  });

  useEffect(() => {
    localStorage.setItem('locationCache', JSON.stringify([...locationCache]));
  }, [locationCache]);

  function getCountriesCoordinates() {
    return countriesCoordinates;
  }

  function setCountriesData(names) {
    const coords = getCountriesCoordinates();


    const rawData = names.map((item) => {
      return {
        name: item,
coordinates: coords.find((coord) => coord.name == item) ? coords.find((coord) => coord.name == item).coordinates : [] ,
      };
    });

    setCountries(rawData);
  }




  useEffect(() => {
 


      if (props.scans){
let data=props.scans

  





  

   /*  for (let i=0;i<data.length;i++){


      const cacheKey = `${data[i].location.latitude},${data[i].location.longitude}`;

      if (locationCache.has(cacheKey)) {
        data[i].location.city=locationCache.get(cacheKey).city
        data[i].location.country=locationCache.get(cacheKey).country

        continue
      }
      
     axios
      .get(
     `https://maps.googleapis.com/maps/api/geocode/json?latlng=${data[i].location.latitude},${data[i].location.longitude}&key=AIzaSyBxt5YShqlP_GhOlbfO6iDJ18nLsaIeLFw`
      )
      .then((res) => {
        const data = res.data;


        if (data.status === 'OK' && data.results.length > 0) {
          const addressComponents = data.results[0].address_components;
          
          let city = '';
          let country = '';
          
          // Extract city and country from address components
          addressComponents.forEach(component => {
            if (component.types.includes('locality')) {
              city = component.long_name;
            }
            if (component.types.includes('country')) {
              country = component.long_name;
            }
          });
  
          const result = createLocationResult(city, country);
        
          // Update cache
          setLocationCache(prev => {
            const newCache = new Map(prev);
            newCache.set(cacheKey, result);
            return newCache;
          });

        }
      })
      .catch((e) => {
        showToast({
          status: "error",
          title: "Server error",
          description: "Something went wrong while fetching scans",
        });
        console.log(e,"tttt")
      }); 
    } */


    const countryNames = Array.from(
      new Set(data.map((item) => item.location.country))
    );

    setCountriesData(countryNames);

    setScans(data);

    }
      
  }, [  props.scans]);


  

  return (
    <Flex>
      <Card overflowX="scroll" pb="22px">
        <CardHeader p="6px 0px 22px 0px">
          <Text fontSize="xl" color={textColor} fontWeight="bold">
            Products Scan Summary 
          </Text>
      
        </CardHeader>

        <CardBody>
          <div>
            <div className="google-map">
              {scans.length > 0 ? (
                <GoogleMap
                  defaultCenter={[
                    scans[0].location.latitude,
                    scans[0].location.longitude,
                  ]}
                  center={currentCenter}
                  zoom={currentZoom}
                  onChange={(e) => changeMapInfo(e)}
                >
                  {scans.length > 0 &&
                    currentZoom < 6 &&
                    countries.map((item, index) => (
                      <MapMarker
                        key={index}
                        lat={item.coordinates[0]}
                        lng={item.coordinates[1]}
                        indexT={index}
                        country={item.name}
                        totalScans={
                          scans.filter(
                            (x) =>
                              x.location.country == item.name
                          ).length
                        }
                        showLocationInfo={showLocationInfo}
                      //handleClickInfo={() => fetchCountryScans(index)}
                       handleClickInfo={() => handleClickInfo(index)}
                        openScansModal={(e) =>
                          openCountryScansModal(
                       
                            item.name
                          )
                        }
                        suspicious={
                        "#ff3300"
                        }
                      />
                    ))}
                  { currentZoom >= 6 &&
                    scans.map((item, index) => (
                      <MapMarker
                        key={index}
                        lat={item.location.latitude}
                        lng={item.location.longitude}
                        text={item.productId}
                        city={item.location.city}
                        country={item.location.country}
                        isCity={true}
                        totalScans={
                      currentZoom>=15 ?  scans.filter(
                        (x) =>
                       x.location.latitude == item.location.latitude && x.location.longitude == item.location.longitude
                      ).length : (    scans.filter(
                            (x) =>
                              x.location.city == item.location.city  &&
                              item.location.country == x.location.country 
                          ).length  )

                        }
                        indexT={index}
                        showLocationInfo={showLocationInfo}
                        handleClickInfo={() => handleClickInfo(index)}
                        openScansModal={(e) =>
                          openCityScansModal(
                            e,
                            item.location.city,
                            item.location.country,
                            item.location.latitude,
                            item.location.longitude,
                          )
                        }
                        suspicious={
                          item.flag == 0 || item.flag == undefined
                            ? "#ff3300"
                            : item.flag == 2
                            ? "gray"
                            : "yellow"
                        }
                      />
                    ))}
                </GoogleMap>
              ) : (
                <GoogleMap
                  center={currentCenter}
                  zoom={currentZoom}
                  onChange={(e) => changeMapInfo(e)}
                ></GoogleMap>
              )}
            </div>
          </div>
        </CardBody>
      </Card>
      {isOpen && (
        <ScanModal
          isOpen={isOpen}
          onOpen={onOpen}
          onClose={onClose}
          selectedCity={selectedCity}
          //data={getScanData(scans)} 

            /*   data={ scans.filter(
                (x) =>
                  x.location.city == selectedCity  &&
                  (x.location.country == selectedCountry && x.location.city != undefined ||                x.location.country == selectedCountry && x.location.city == undefined && x.location.latitude == x.location.latitude && x.location.longitude == x.location.longitude)
              )  */
data={scans.filter(
  (x) =>
currentZoom>=6 ?  x.location.city == selectedCity :
  x.location.country == selectedCountry)
}
             
          suspiciousTags={tagStatus}
        />
      )}
    </Flex>
  );
}

export default ScansDashboard;
