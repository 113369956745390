import { Flex, Select, Text } from "@chakra-ui/react";
import React, { Component } from "react";
import ReactApexChart from "react-apexcharts";
import { monthlyScans } from "variables/charts";
import { allScansSince2020 } from "variables/charts";
import { customScans } from "variables/charts";
import { flattenObject } from "variables/charts";
import { lastYearScans } from "variables/charts";
import { dailyScans } from "variables/charts";
import { currentYearScans } from "variables/charts";
import { trimestralScans } from "variables/charts";
import { weeklyScans } from "variables/charts";

function getAllDaysInMonth(month, year) {
  const daysInMonth = new Date(year, month, 0).getDate();
  const monthName = new Date(year, month, 1).toLocaleString("default", {
    month: "short",
  });

  const daysArray = [];

  for (let day = 1; day <= daysInMonth; day++) {
    daysArray.push(`${day}${monthName}`);
  }

  return daysArray;
}

function getCurrentTrimesterMonths() {
  const now = new Date();
  const currentMonth = now.getMonth();

  const trimesterStartMonths = [0, 3, 6, 9];

  const trimesterIndex = Math.floor(currentMonth / 3);

  const startMonth = trimesterStartMonths[trimesterIndex];

  const monthsInTrimester = [startMonth, startMonth + 1, startMonth + 2];

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const currentTrimesterMonths = monthsInTrimester.map(
    (monthIndex) => monthNames[monthIndex]
  );

  return currentTrimesterMonths;
}
class LineChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      options: {},
      timeRange: "today", // Default time range
      customTimeRange:null
    };
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.data !== this.props.data ||
      this.props.timeRange !== this.state.timeRange
  ||  this.props.customTimeRange !== this.state.customTimeRange
    ) {
      this.loadData();
    }
  }

  loadData() {
    const { timeRange, data , customTimeRange} = this.props;
    var filteredData = [];
    var scans = [];

    if (this.props.country) {
      scans = data.filter(
        (item) => item.location.country == this.props.country
      );
    }
    if (this.props.product) {
      scans = data.filter((item) => item.productId == this.props.product);
    }
    if (timeRange === "currentWeek") {
      // Display only the day of the week for weekly data
      filteredData = weeklyScans(data);
    }

    if (timeRange === "today") {
      filteredData = dailyScans(data).today;
    }
    if (timeRange === "yesterday") {
      filteredData = dailyScans(data).yesterday;
    }

    if (timeRange === "currentTrimester") {
      filteredData = trimestralScans(data);
    }

    if (timeRange === "lastYear") {
      filteredData = lastYearScans(data);
    }

    if (timeRange === "currentYear") {
      filteredData = currentYearScans(data);
    }

    if (timeRange === "currentMonth") {
      filteredData = monthlyScans(data);
    }
    if (timeRange === "custom" && customTimeRange ) {

      filteredData = customScans(data, customTimeRange[0], customTimeRange[1])
    }
    
    if (timeRange === "all") {
      filteredData = allScansSince2020(data);
    }
   


    // Update the component state with the filtered data and options
    this.setState({
      data: [{ name: "scan", data: filteredData }],
      options: this.generateChartOptions(timeRange,  customTimeRange),
      timeRange: timeRange,
      customTimeRange: customTimeRange,
    });
  }

  generateChartOptions(timeRange, customTimeRange) {
    // Customize the options based on your requirements
    const options = {
     
 
      
      xaxis: {
        type: "timedate",
        
        labels: {
          formatter: function (value) {
           
            if (value && value.includes('/')) {
              const [month, year] = value.split('/');
              return `${month}\n${year}`; // e.g., "Jan\n2020"
            }
            return value; // For other months, just return the month
 
          },
        },
        axisTicks: {
          show: false,
        },
        dataLabels: {
          enabled: true,
        },
        axisBorder: {
          show: false,
        },
       
        labels: {
          style: {
            colors: "#fff",
            fontSize: "12px",
          },
        },
      },
      yaxis: {
     
        labels: {
          formatter: function (value) {

            return value 
          },
          style: {
            colors: "#fff",
            fontSize: "12px",
          },
        },
        min: 0, // Ensure the y-axis starts at 0
      },
      // ... other chart options
    };
    if (timeRange === "currentWeek") {
      options.xaxis.categories = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
    } else if (timeRange === "today" || timeRange === "yesterday") {
      //options.xaxis.categories = data.map((item) => new Date(item.createdAt).toLocaleDateString(undefined, { hour: '2-digit', minute: '2-digit' }));
      options.xaxis.categories = [];

      for (let i = 0; i < 24; i++) {
        const hours = (i % 12 || 12).toString().padStart(2, "0");
        const amPm = i < 12 ? "AM" : "PM";
        options.xaxis.categories.push(hours + " " + amPm);
      }
    } else if (timeRange === "currentMonth") {
      /*    options.xaxis.categories = data.map((item) =>
        new Date(item.createdAt).getDate()
      ); */
      options.xaxis.categories = getAllDaysInMonth(
        new Date().getMonth(),
        new Date().getFullYear()
      );
    } else if (timeRange === "currentTrimester") {
      options.xaxis.categories = getCurrentTrimesterMonths();
    } else if (timeRange === "currentYear" || timeRange === "lastYear") {
      options.xaxis.categories = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
    }

    if (timeRange=="all"){


      options.xaxis.categories=getAllMonthsSince2020()
      options.xaxis.categories=options.xaxis.categories.map((value)=>{
        if (value && value.includes('/')) {
          const [month, year] = value.split('/');
          return `${year}`;
        }
        return value; // For other months, just return the month
      })

    }

if (timeRange=="custom" && customTimeRange ){
  options.xaxis.categories= getDatePeriod(new Date(customTimeRange[0]), new Date(customTimeRange[1]))

}

    return options;
  }

  
  handleTimeRangeChange = (event) => {
    const newTimeRange = event.target.value;

    // Update the timeRange state
    this.setState({ timeRange: newTimeRange });
  };

  getTimeText = (timeRange) => {
    const timeText = {
      today: "Today",
      yesterday: "Yesterday",
      currentWeek: "Curernt Week",
      currentMonth: "Current Month",
      currentTrimester: "Current Trimester",
      currentYear: "Current Year",
      lastYear: "Last Year",
      all: "All"
    };

    return timeText[timeRange];
  };

  render() {
    const { data, options, timeRange, customTimeRange } = this.state;

    return (
      <div>
        <Flex direction="row" mb="40px" p="28px 0px 0px 22px">
          <Flex direction="column" mb="40px" p="28px 0px 0px 22px">
            <Text color="#fff" fontSize="lg" fontWeight="bold" mb="6px">
              {this.getTimeText(timeRange)} Scans Overview


            </Text>

             
          </Flex>
        </Flex>

        {/* Render the ApexCharts component */}
        <ReactApexChart
          options={options}
          series={data}
          type="area"
          width="100%"
          height="100%"
        />
      </div>
    );
  }
}

export default LineChart;

function getAllMonthsSince2020() {
  const months = [];
  const startDate = new Date(2020, 0, 1); // January 2020
  const endDate = new Date(2025, 2, 17);  // March 17, 2025
  let currentDate = new Date(startDate);

  while (currentDate <= endDate) {
    const year = currentDate.getFullYear();
    const month = currentDate.toLocaleString("default", { month: "short" });

    // For January, include the year
    if (currentDate.getMonth() === 0) { // January (0-based month)
      months.push(`${month}/${year}`);
    } else {
      // For other months, just add the month name
      months.push(month);
    }

    currentDate.setMonth(currentDate.getMonth() + 1);
  }

  return months;
}





function getDatePeriod(fromDate, toDate) {

  const from = new Date(fromDate);
  const to = new Date(toDate);

  const periodArray = [];
  let currentDate = new Date(from);

  while (currentDate <= to) {
    const month = currentDate.toLocaleString("default", { month: "short" });
    const day = currentDate.getDate();
    periodArray.push(`${month}/${day}`);
    
    // Move to the next day correctly, including transitions between months
    currentDate.setDate(currentDate.getDate() + 1); // This is fine for day-by-day iteration
  }

  return periodArray;
}