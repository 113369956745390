export const barChartData = [
  {
    name: "Sales",
    data: [15, 25, 28, 10, 25, 20],
  },
];

export const orderStatus = [
  "sent",
  "being processed",
  "ready",
  "delivered",
  "canceled",
];

export const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const barChartOptions = {
  chart: {
    toolbar: {
      show: false,
    },
  },
  tooltip: {
    theme: "dark",
  },
  xaxis: {
    categories: months,
    labels: {
      style: {
        colors: "#A0AEC0",
        fontSize: "12px",
      },
    },
    show: true,
    axisBorder: {
      show: false,
    },
  },
  yaxis: {
    show: true,
    color: "#A0AEC0",
    labels: {
      show: true,
      style: {
        colors: "#A0AEC0",
        fontSize: "14px",
      },
    },
  },
  fill: {
    colors: "#ED8936",
  },
  dataLabels: {
    enabled: false,
  },
  grid: {
    strokeDashArray: 5,
  },
  plotOptions: {
    bar: {
      borderRadius: 3,
      columnWidth: "15px",
    },
  },
  responsive: [
    {
      breakpoint: 768,
      options: {
        plotOptions: {
          bar: {
            borderRadius: 0,
          },
        },
      },
    },
  ],
};

/* export const lineChartData = [
  {
    name: "Scan",
    data: [50, 40, 300, 920, 500, 250, 400, 230, 500],
  },
]; */

/* export const dailyScans = (data) => {
  const currentDate = new Date();
  const today = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()).setHours(0, 0, 0, 0);
  const yesterday = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 1).setHours(0, 0, 0, 0);

  const filteredData = data.filter((item) => {
    const itemDate = new Date(item.createdAt).setHours(0, 0, 0, 0);
    return itemDate === today || itemDate === yesterday;
  });

  let result = Array(2).fill(0); // Initialize an array of length 2 with 0s

  filteredData.forEach((item) => {
    const itemDate = new Date(item.createdAt).setHours(0, 0, 0, 0);
    if (itemDate === today) {
      result[0]++;
    } else if (itemDate === yesterday) {
      result[1]++;
    }
  });
  console.log(result, "daily")

  return result;
};
 */

export const dailyScans = (data) => {
  const currentDate = new Date();
  const utcCurrentDate = new Date(
    Date.UTC(
      currentDate.getUTCFullYear(),
      currentDate.getUTCMonth(),
      currentDate.getUTCDate()
    )
  );

  const today = new Date(utcCurrentDate);
  today.setUTCHours(0, 0, 0, 0);

  const yesterday = new Date(utcCurrentDate);
  yesterday.setUTCDate(utcCurrentDate.getUTCDate() - 1);
  yesterday.setUTCHours(0, 0, 0, 0);

  const filteredData = data.filter((item) => {
    const itemDate = new Date(item.createdAt);
    const utcItemDate = new Date(
      Date.UTC(
        itemDate.getUTCFullYear(),
        itemDate.getUTCMonth(),
        itemDate.getUTCDate()
      )
    );
    return (
      (utcItemDate.getUTCFullYear() === today.getUTCFullYear() &&
        utcItemDate.getUTCMonth() === today.getUTCMonth() &&
        utcItemDate.getUTCDate() === today.getUTCDate()) ||
      (utcItemDate.getUTCFullYear() === yesterday.getUTCFullYear() &&
        utcItemDate.getUTCMonth() === yesterday.getUTCMonth() &&
        utcItemDate.getUTCDate() === yesterday.getUTCDate())
    );
  });

  let resultToday = Array(24).fill(0); // Initialize an array of length 24 with 0s
  let resultYesterday = Array(24).fill(0); // Initialize an array of length 24 with 0s

  filteredData.forEach((item) => {
    const itemDate = new Date(item.createdAt);
    const utcItemDate = new Date(
      Date.UTC(
        itemDate.getUTCFullYear(),
        itemDate.getUTCMonth(),
        itemDate.getUTCDate(),
        itemDate.getUTCHours()
      )
    );
    const hours = utcItemDate.getUTCHours(); // Get the hours component
    if (
      utcItemDate.getUTCFullYear() === today.getUTCFullYear() &&
      utcItemDate.getUTCMonth() === today.getUTCMonth() &&
      utcItemDate.getUTCDate() === today.getUTCDate()
    ) {
      resultToday[hours]++;
    } else if (
      utcItemDate.getUTCFullYear() === yesterday.getUTCFullYear() &&
      utcItemDate.getUTCMonth() === yesterday.getUTCMonth() &&
      utcItemDate.getUTCDate() === yesterday.getUTCDate()
    ) {
      resultYesterday[hours]++;
    }
  });
  return {
    today: resultToday,
    yesterday: resultYesterday,
  };
};

export const weeklyScans = (data) => {
  const currentDate = new Date();
  const startOfWeek = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate() - currentDate.getDay()
  ).setHours(0, 0, 0, 0);
  const endOfWeek = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate() + (6 - currentDate.getDay())
  ).setHours(0, 0, 0, 0);

  const filteredData = data.filter((item) => {
    const itemDate = new Date(item.createdAt).setHours(0, 0, 0, 0);
    return itemDate >= startOfWeek && itemDate <= endOfWeek;
  });

  let result = Array(7).fill(0); // Initialize an array of length 7 with 0s

  filteredData.forEach((item) => {
    const itemDay = new Date(item.createdAt).getDay();
    result[itemDay]++;
  });

  return result;
};

export const trimestralScans = (data) => {
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth() + 1;
  const startMonth = currentMonth - ((currentMonth - 1) % 3);
  const endMonth = startMonth + 2;

  const filteredData = data.filter((item) => {
    const itemMonth = new Date(item.createdAt).getMonth() + 1;

    return itemMonth >= startMonth && itemMonth <= endMonth;
  });

  let result = Array(3).fill(0); // Initialize an array of length 3 with 0s

  filteredData.forEach((item) => {
    const itemMonth = new Date(item.createdAt).getMonth() + 1;
    result[itemMonth - startMonth]++;
  });
  return result;
};

export const currentYearScans = (data) => {
  const currentYear = new Date().getFullYear();

  const filteredData = data.filter((item) => {
    const itemYear = new Date(item.createdAt).getFullYear();
    return itemYear === currentYear;
  });

  let result = Array(12).fill(0); // Initialize an array of length 12 with 0s

  filteredData.forEach((item) => {
    const itemMonth = new Date(item.createdAt).getMonth();
    result[itemMonth]++;
  });

  return result;
};

export const lastYearScans = (data) => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const lastYear = currentYear - 1;

  const filteredData = data.filter((item) => {
    const itemYear = new Date(item.createdAt).getFullYear();
    return itemYear === lastYear;
  });

  let result = Array(12).fill(0); // Initialize an array of length 12 with 0s

  filteredData.forEach((item) => {
    const itemMonth = new Date(item.createdAt).getMonth();
    result[itemMonth]++;
  });

  return result;
};

export const getProductScans = (scans, id) => {
  var dates = scans.filter((item) => item.productId == id);

  return dates.length;
};

export const getMonthlyOrders = (data) => {
  var ordersByMonth = [];
  for (var i = 0; i < 12; i++) {
    const month = data.filter(
      (item) => new Date(item.createdAt).getMonth() == i
    );
    ordersByMonth[i] = month;
  }

  const sums = [];
  for (var i = 0; i < 12; i++) {
    if (ordersByMonth[i].length != 0) {
      let sum = 0;
      for (var j = 0; j < ordersByMonth[i].length; j++) {
        sum += parseInt(ordersByMonth[i][j].quantity);
      }
      sums[i] = sum;
    } else {
      sums[i] = 0;
    }
  }

  return sums;
};

export const getTotalScansByCountry = (data) => {
  var countries = data.map((item) => item.location.country);
  //countries = [...new Set(countries.flat())];
  const occurrences = countries.reduce(function (acc, curr) {
    return acc[curr] ? ++acc[curr] : (acc[curr] = 1), acc;
  }, {});

  return occurrences;
};

export const getDailyScans = (data) => {
  var dates = data.map((item) => item.createdAt);
  let today = 0;
  for (var i = 0; i < dates.length; i++) {
    if (
      new Date(dates[i]).getTime() >=
      new Date().getTime() - 24 * 60 * 60 * 1000
    ) {
      today += 1;
    }
  }

  return today;
};

/* export const lineChartOptions = {
  chart: {
    toolbar: {
      show: false,
    },
  },
  tooltip: {
    theme: "dark",
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: "smooth",
  },
  xaxis: {
    type: "datetime",
    categories: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    axisTicks: {
      show: false,
    },
    axisBorder: {
      show: false,
    },
    labels: {
      style: {
        colors: "#fff",
        fontSize: "12px",
      },
    },
  },
  yaxis: {
    labels: {
      style: {
        colors: "#fff",
        fontSize: "12px",
      },
    },
  },
  legend: {
    show: false,
  },
  grid: {
    strokeDashArray: 5,
  },
  fill: {
    type: "gradient",
    gradient: {
      shade: "light",
      type: "vertical",
      shadeIntensity: 0.5,
      inverseColors: true,
      opacityFrom: 0.8,
      opacityTo: 0,
      stops: [],
    },
    colors: ["#fff", "#3182CE"],
  },
  colors: ["#fff", "#3182CE"],
}; */

export const lineChartOptions = {
  chart: {
    toolbar: {
      show: false,
    },
  },
  tooltip: {
    theme: "dark",
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: "smooth",
  },
  xaxis: {
    type: "category", // Changed from "datetime" to "category" to handle custom labels
    categories: [], // Will be set dynamically, initially empty
    axisTicks: {
      show: false,
    },
    axisBorder: {
      show: false,
    },
    labels: {
      formatter: function (value) {
        console.log(value)
        // Split "Jan/YYYY" into two lines using a newline character
        if (value && value.includes('/')) {
  
          const [month, year] = value.split('/');
          return `${month}\n${year}`; // e.g., "Jan\n2020"
        }
        return value; // For other months, just return the month
      },
      style: {
        colors: "#fff",
        fontSize: "12px",
        cssClass: 'apexcharts-xaxis-label', // Optional: for further styling
      },
      offsetY: 5, // Adjust vertical positioning if needed
      textAnchor: 'middle', // Center-align the text
    },
  },
  yaxis: {
    labels: {
      style: {
        colors: "#fff",
        fontSize: "12px",
      },
    },
  },
  legend: {
    show: false,
  },
  grid: {
    strokeDashArray: 5,
  },
  fill: {
    type: "gradient",
    gradient: {
      shade: "light",
      type: "vertical",
      shadeIntensity: 0.5,
      inverseColors: true,
      opacityFrom: 0.8,
      opacityTo: 0,
      stops: [],
    },
    colors: ["#fff", "#3182CE"],
  },
  colors: ["#fff", "#3182CE"],
};
export const filterDataByTimeRange = (data, timeRange) => {
  const currentDate = new Date();

  if (timeRange === "Today") {
    // Filter logic for today
    const startOfToday = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate()
    );
    const endOfToday = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate(),
      23,
      59,
      59
    );

    const filteredData = data.filter((item) => {
      const itemDate = new Date(item.createdAt);
      return itemDate >= startOfToday && itemDate <= endOfToday;
    });

    return filteredData;
  }

  if (timeRange === "Yesterday") {
    // Filter logic for yesterday
    const startOfYesterday = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() - 1
    );
    const endOfYesterday = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() - 1,
      23,
      59,
      59
    );

    const filteredData = data.filter((item) => {
      const itemDate = new Date(item.createdAt);
      return itemDate >= startOfYesterday && itemDate <= endOfYesterday;
    });

    return filteredData;
  }

  if (timeRange === "Current week") {
    // Filter logic for the current week
    const startOfCurrentWeek = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() - currentDate.getDay()
    );
    const endOfCurrentWeek = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate()
    );

    const filteredData = data.filter((item) => {
      const itemDate = new Date(item.createdAt);
      return itemDate >= startOfCurrentWeek && itemDate <= endOfCurrentWeek;
    });

    return filteredData;
  }

  if (timeRange === "Current month") {
    // Filter logic for the current month
    const startOfCurrentMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );
    const endOfCurrentMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      0,
      23,
      59,
      59
    );

    const filteredData = data.filter((item) => {
      const itemDate = new Date(item.createdAt);
      return itemDate >= startOfCurrentMonth && itemDate <= endOfCurrentMonth;
    });

    return filteredData;
  }

  if (timeRange === "Current Trimester") {
    // Filter logic for the current trimester
    const currentMonth = currentDate.getMonth();
    const startOfCurrentTrimester = new Date(
      currentDate.getFullYear(),
      Math.floor(currentMonth / 3) * 3,
      1
    );
    const endOfCurrentTrimester = new Date(
      currentDate.getFullYear(),
      Math.floor(currentMonth / 3) * 3 + 3,
      0,
      23,
      59,
      59
    );

    const filteredData = data.filter((item) => {
      const itemDate = new Date(item.createdAt);
      return (
        itemDate >= startOfCurrentTrimester && itemDate <= endOfCurrentTrimester
      );
    });

    return filteredData;
  }

  if (timeRange === "Current Year") {
    // Filter logic for the current year
    const startOfCurrentYear = new Date(currentDate.getFullYear(), 0, 1);
    const endOfCurrentYear = new Date(
      currentDate.getFullYear(),
      11,
      31,
      23,
      59,
      59
    );

    const filteredData = data.filter((item) => {
      const itemDate = new Date(item.createdAt);
      return itemDate >= startOfCurrentYear && itemDate <= endOfCurrentYear;
    });

    return filteredData;
  }

  if (timeRange === "Last Year") {
    // Filter logic for the last year
    const startOfLastYear = new Date(currentDate.getFullYear() - 1, 0, 1);
    const endOfLastYear = new Date(
      currentDate.getFullYear() - 1,
      11,
      31,
      23,
      59,
      59
    );

    const filteredData = data.filter((item) => {
      const itemDate = new Date(item.createdAt);
      return itemDate >= startOfLastYear && itemDate <= endOfLastYear;
    });
    const chartData = filteredData.reduce((result, item) => {
      const date = new Date(item.createdAt);
      result[date] = (result[date] || 0) + 1;
      return result;
    }, {});

    const chartSeries = Object.values(chartData);

    return filteredData;
  }

  return [];
};

export const flattenObject = (object) => {
  const ipAddress = object.ipAddress;
  const location = object.location;
  const createdAt = object.createdAt;

  const result = [];

  for (let i = 0; i < ipAddress.length; i++) {
    const flattenedObject = {
      ipAddress: ipAddress[i],
      location: location[i],
      companyId: object.companyId,
      createdAt: Array.isArray(createdAt[i]) ? createdAt[i][0] : createdAt[i],
      id: object.id,
      tagId: object.tagId,
      productId: object.productId,
    };

    result.push(flattenedObject);
  }

  return result;
};

export const monthlyScans = (filteredData) => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();

  const daysInMonth = new Date(currentYear, currentMonth + 1, 0).getDate(); // Get the number of days in the current month



  let result = Array(daysInMonth).fill(0); // Initialize an array of length 'daysInMonth' with 0s

  filteredData.forEach((item) => {
    const itemDay = new Date(item.createdAt).getDate();
    result[itemDay - 1]++; // Increment the corresponding day index in the 'result' array
  });

  return result;
};

export const customScans = (filteredData, fromDate, toDate) => {
  const from = new Date(fromDate);
  const to = new Date(toDate);

  // Get the number of days in the period (fromDate to toDate)
  const daysInRange = Math.floor((to - from) / (1000 * 60 * 60 * 24)) + 1; // Including the end day
  const result = Array(daysInRange).fill(0); // Initialize an array with the length of the date range

  filteredData.forEach((item) => {
    const itemDate = new Date(item.createdAt);
    if (itemDate >= from && itemDate <= to) {
      const dayIndex = Math.floor((itemDate - from) / (1000 * 60 * 60 * 24)); // Calculate the day index in the range
      result[dayIndex]++; // Increment the corresponding day in the 'result' array
    }
  });

  return result;
};


/* export const allScansSince2020 = (data) => {
  const currentYear = new Date().getFullYear();

  const filteredData = data.filter((item) => {
    const itemYear = new Date(item.createdAt).getFullYear();
    return itemYear === currentYear;
  });

  let result = Array(12).fill(0); // Initialize an array of length 12 with 0s

  filteredData.forEach((item) => {
    const itemMonth = new Date(item.createdAt).getMonth();
    result[itemMonth]++;
  });

  return result;
}; */

export const allScansSince2020 = (data) => {
  const startDate = new Date(2022, 0, 1); // January 2020
  const endDate = new Date()  // March 18, 2025 (current date based on your context)

  // Calculate total number of months from January 2020 to March 2025
  const totalMonths = 
    (endDate.getFullYear() - startDate.getFullYear()) * 12 + 
    (endDate.getMonth() - startDate.getMonth()) + 1;

  // Initialize result array with 0s for each month since January 2020
  let result = Array(totalMonths).fill(0);

  // Process all data without filtering by year (since we want all since 2020)
  data.forEach((item) => {
    const itemDate = new Date(item.createdAt);
    const itemYear = itemDate.getFullYear();
    const itemMonth = itemDate.getMonth();

    // Calculate the month index relative to January 2020
    const monthIndex = 
      (itemYear - 2022) * 12 + 
      itemMonth;

    // Only increment if the date is within our range
    if (itemDate >= startDate && itemDate <= endDate) {
      result[monthIndex]++;
    }
  });

  return result;
};