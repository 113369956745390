import { Input, useColorMode } from "@chakra-ui/react";
import React, { useCallback, useState } from "react";
import axios from "axios";
import { DownloadIcon } from "@chakra-ui/icons";
import * as XLSX from "xlsx";
import { useDropzone } from "react-dropzone";

const CSVUploader = () => {
  const [csvData, setCSVData] = useState(null);
  const { colorMode } = useColorMode();

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];

      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 2 });


      setCSVData(jsonData.slice(1));
    };

    reader.readAsArrayBuffer(file);
  };

  const handleDownloadButtonClick = async () => {
    try {
      const response = await axios.get(
        `/s3?key=troofmedia/template_troof.xlsx`,
        {
          responseType: "blob",
        }
      );

      const blob = new Blob([response.data], { type: "text/csv" });

      const url = URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.download = "example.csv";

      link.click();

      URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const onDrop = useCallback((acceptedFiles) => {
    handleFileUpload(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: ".xlsx, .csv",
  });

  return (
    <div style={{ flex: "center", overflowX: "auto" }}>
      <button
        onClick={handleDownloadButtonClick}
        style={{
          margin: "10px 10px 10px 20px",
          padding: "5px",
          borderRadius: "5px",
          border: "solid",
          display: "center",
        }}
      >
        Download Template
      </button>
      <div style={{ margin: "10px", padding: "5px", padding: "10px" }}>
        <label>Upload products</label>
        <div
          {...getRootProps()}
          style={{
            borderRadius: "10px",
            padding: "20px",
            borderColor: "gray",
            alignItems: "center",
            justifyItems: "center",
            justifyContent: "center",
            display: "flex",
            backgroundColor: "transparent",
            border: "dotted",
            marginTop: "15px",
            color: colorMode,
            cursor: "pointer",
          }}
        >
          <input {...getInputProps()} />
          {isDragActive ? (
            <p>Drop the file here ...</p>
          ) : (
            <>
              <DownloadIcon w={6} h={6} marginRight={"4px"} />
              <p>Drag 'n' drop your file here, or click to select a file</p>
            </>
          )}
        </div>
        {csvData && (
          <table style={{ width: "100px", marginTop: "20px" }}>
            <thead>
              <tr>
                {Object.values(csvData[0]).map((header) => (
                  <th key={header} style={{ whiteSpace: "nowrap" }}>
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {csvData.slice(1).map((row, index) => (
                <tr key={index}>
                  {Object.values(row).map((value) => (
                    <td key={value}>{value}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default CSVUploader;
