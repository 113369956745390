// Chakra imports
import {
  Flex,
  Table,
  Tbody,
  Text,
  Th,
  Button,
  Thead,
  Tr,
  useColorModeValue,
  useToast,
  Box,
  Select,
  useDisclosure,
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { getTotalScansByCountry } from "variables/charts";
import { countriesCoordinates } from "variables/map";
import React, { useEffect, useState } from "react";
import axios from "axios";
import "./map.css";
import ScansTable from "components/Tables/ScansTable";
import useAuth from "views/Pages/useAuth";
import Pagination from "components/Tables/Pagination";
import { useLocation } from "react-router-dom";
import LoadingIcon from "components/Icons/LoadingIcon";
import ScansModal from "components/modals/scansModal";
import { ArrowDownIcon, ArrowLeftIcon, ArrowUpIcon } from "@chakra-ui/icons";
import { parseMultilingualText } from "utils";

function Scans() {
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const [scans, setScans] = useState([]);
  const [scansCount, setScansCount] = useState(0);
  const { company } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = React.useState(1);
  const location = useLocation();
  const [timeRange, setTimeRange] = useState("today");
  const [distributorFilter, setDistributorFilter] = useState("");
  const [countryFilter, setCountryFilter] = useState();
  const [productFilter, setProductFilter] = useState("");
  const [lastScanElement, setLastScanElement] = useState({});
  const [distributors, setDistributors] = useState([]);
  const [products, setProducts] = useState([]);
  const [scan, setScan] = useState();
  const [orderAlph, setOrderAlph] = useState("desc");
  const [orderCount, setOrderCount] = useState("desc");
  const [urlWithFilter, setUrlWithFilter] = useState("");
  const [filteredScans, setFilteredScans] = useState([]);

  //const [orderProductByName, setOrderProductByName] = useState(false);
  const [countries, setCountries] = useState(
    countriesCoordinates.map((item) => item.name)
  );

  const { isOpen, onOpen, onClose } = useDisclosure();

  const limit = 12;

  const toast = useToast();

  const handlePageChange = (page) => {
    setCurrentPage(page);
if (timeRange !="" || distributorFilter || countryFilter){
  fetchScansWithFilterOn(filteredScans, page)
}else{
    fetchScans(lastScanElement, page);
}
  };

  const showToast = ({ status, title, description }) => {
    toast({
      title: title,
      description: description,
      status: status,
      duration: 5000,
      isClosable: true,
      position: "top-right",
    });
  };

  const getDistributorCompany = async () => {
    return axios
      .get(`/distributor?companyId=${company}`)
      .then((response) => {
        setDistributors(response.data);
      })
      .catch((e) => {
        showToast({
          status: "error",
          title: "Server error",
          description: "Something went wrong when fetching the distributors",
        });
      });
  };

  const getProductsCompany = async () => {
    return axios
      .get(`/product?companyId=${company}&all=true`)
      .then((res) => {
        setProducts(res.data);
      })
      .catch((e) => {
        showToast({
          status: "error",
          title: "Server error",
          description: "Something went wrong when fetching the products",
        });
      });
  };


  const fetchScansWithFilterOn = async (scans, page = 1) => {
        setScans(scans.slice((page - 1) * limit, page * limit));    
  };

  const orderProductByName =  (order, data) => {
  const sorted=sortByField(data, "product",order )
  setScans(sorted)
};

const orderScanByCount =  (order, data) => {
const s= data.sort((a, b) => {
    const roomsA = a?.ipAddress.length;
    const roomsB = b?.ipAddress.length;

    if (order === 'asc') {
        return roomsA - roomsB; // Ascending order
    } else if (order === 'desc') {
        return roomsB - roomsA; // Descending order
    } else {
        throw new Error("Invalid order parameter. Use 'asc' or 'desc'.");
    }
});
setScans(s)
};


  const fetchScans = async (last = {}, page = 1) => {

    const searchParams = new URLSearchParams(location.search);
    const tagId = searchParams.get("tag");
    if (tagId) {
      return;
    }
    var lastQueryParam = "";
    if (Object.keys(last).length != 0) {
      lastQueryParam = "&" + encodeURIComponent(JSON.stringify({ last: last }));
    }

    setIsLoading(true);
    axios
      .get(
        `/scan?all=true&companyId=${company}&limit=${
          page * limit
        }${lastQueryParam}`
      )
      .then((res) => {
        setScans(res.data.scans.slice((page - 1) * limit, page * limit));
        setLastScanElement(res.data.lastEvaluatedKey);
        return res.data;
      })
      .catch((e) => {
        showToast({
          status: "error",
          title: "Server error",
          description: e,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const buildParams = () => {

    const searchParams = new URLSearchParams(location.search);

  
    const filters=searchParams.get("filters")
    

    if(filters){

      searchParams.delete("filters");
      window.history.replaceState({}, "", `#/admin/scans`);
return `filter=true&companyid=${company}`+filters
    }else{
    const params = new URLSearchParams();
    params.append("companyid", company);
    params.append("filter", "true");
    if (countryFilter) params.append("country", countryFilter);
    if (distributorFilter) params.append("distributor", distributorFilter);
    if (timeRange) params.append("time", timeRange);
    if (productFilter) params.append("product", productFilter);

    return params;
    }
    
  };

  const fetchScansWithFilter = async (page=1) => {
    setIsLoading(true);
    const params = buildParams();



    await axios
      .get(`/scan?${params.toString()}`)
      .then((res) => {
        const mergedData = res.data.reduce((acc, obj) => {
          const existingObj = acc.find((item) => item.tagId === obj.tagId);

          if (existingObj) {
            existingObj.location.push(obj.location);
            existingObj.ipAddress.push(obj.ipAddress);
            existingObj.createdAt.push(obj.createdAt);
          } else {
            acc.push({
              ...obj,
              location: [obj.location],
              ipAddress: [obj.ipAddress],
              createdAt: [obj.createdAt],
              product : products.filter((x) => x.productId == obj.productId)[0]
            });

          }
        
          return acc;
        }, []);


        setFilteredScans(mergedData)
 
        setScans(mergedData.slice((page - 1) * limit, page * limit));
        setScansCount(mergedData.length);
        setIsLoading(false);

      })
      .catch((e) => {
        setIsLoading(false);
        console.log(e)
        showToast({
          status: "error",
          title: "Server error",
          description: "Something went wrong when fetching the scanszz",
        });
      
      });


  };

  const fetchScan = async () => {
    const searchParams = new URLSearchParams(location.search);
    const tagId = searchParams.get("tag");
    if (tagId) {
      setIsLoading(true);
      axios
        .get(`/scan?tag=${tagId}&company=${company}&getAllTagScans=true`)
        .then(async (res) => {
          setScans([res.data]);
          setScan(res.data);
          setScansCount([res.data].length);
          setIsLoading(false);
        })
        .catch((e) => {
          setIsLoading(false);
        });
    }
  };

  const fetchScansCount = async () => {
    setIsLoading(true);
    axios
      .get(`/scan?count=true&companyId=${company}`)
      .then(async (res) => {
        setScansCount(res.data.count);
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (!company) return;

    const queryParams = new URLSearchParams(location.search);
    const yourQueryParam = queryParams.get("tag");
    const searchParams = new URLSearchParams(location.search);

  
 const filters=searchParams.get("filters")
setUrlWithFilter(filters) 


    if (yourQueryParam != "") {
      Promise.all([
        fetchScansWithFilter(currentPage) ,
        getProductsCompany(),
        getDistributorCompany(),
        //fetchScansCount(),
      ]);
    } else {
      fetchScan();
    }

  

   
  }, [company]);

  /*  useEffect(() => {
    //if (!company) return;
    const searchParams = new URLSearchParams(location.search);

    if (searchParams.get("filters")) {
const filters=searchParams.get("filters")
const params = new URLSearchParams(filters);

// Get values for country and time

const timeValue = params.get('time');
setTimeRange(timeValue)

    }

  }, [company]);  */
 

  const selectScans = (scan) => {
    setScan(scan);
    onOpen();
  };

  const clickOrderAlph = () => {
if (orderAlph=="asc"){
setOrderAlph("desc")
}else{
  setOrderAlph("asc")
}
  };

  const clickOrderCount = () => {
    if (orderCount=="asc"){
    setOrderCount("desc")
    }else{
      setOrderCount("asc")
    }
  };

  useEffect(() => {
    if (!company) return;
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.get("tag")) {
      searchParams.delete("tag");
      window.history.replaceState({}, "", `#/admin/scans`);
    }
    
    fetchScansWithFilter(currentPage);


  }, [countryFilter, distributorFilter, timeRange, productFilter]);
  
  useEffect(() => {
    if (!scans || !company) return;
orderProductByName(orderAlph, scans)

  }, [orderAlph, scans]);

  useEffect(() => {
if (!scans || !company) return;
orderScanByCount(orderCount, scans)

  }, [orderCount, scans]);

  return (
    <Flex direction="column" gap={8} pt={{ base: "120px", md: "75px" }}>
      <Card overflowX="scroll" pb="0">
        <CardHeader p="6px 0px 22px 0px">
          <Text fontSize="l" color={textColor} fontWeight="bold">
            Scans
          </Text>
        </CardHeader>
        <CardBody>
          <Card
            p="0px"
            maxW={{ sm: "100%", md: "100%" }}
            style={{ marginBottom: "20px" }}
          >
            <Box>
              <Flex
                direction="row"
                style={{ justifyContent: "start", alignItems: "center" }}
                p="28px 0px 0px 0px"
              >
                <Flex direction="column" p="0px 0px 28px 0px" width={"10%"}>
                  <Text>Filter by: </Text>
                </Flex>
                <Flex direction="column" p="0px 0px 28px 22px">
                  <Select
                    variant="flushed"
                    value={distributorFilter}
                    onChange={(e) => setDistributorFilter(e.target.value)}
                  >
                    <option value="">Distributor</option>
                    {distributors.map((dist, idx) => (
                      <option key={idx} value={dist.distributorId}>
                        {dist?.name}
                      </option>
                    ))}
                  </Select>
                </Flex>
                <Flex direction="column" p="0px 0px 28px 22px">
                  <Select
                    variant="flushed"
                    value={productFilter}
                    onChange={(e) => setProductFilter(e.target?.value)}
                  >
                    <option value="">Product</option>
                    {products.map((product, idx) => (
                      <option key={idx} value={product.productId}>
                        {parseMultilingualText( product.name)}
                      </option>
                    ))}
                  </Select>
                </Flex>
                <Flex direction="column" p="0px 0px 28px 22px">
                  <Select
                    variant="flushed"
                    value={countryFilter}
                    onChange={(e) => setCountryFilter(e.target.value)}
                  >
                    <option value="">Country</option>
                    {countries.map((el, idx) => (
                      <option key={idx} value={el}>
                        {el}
                      </option>
                    ))}
                  </Select>
                </Flex>
                <Flex direction="column" p="0px 0px 28px 22px">
                  <Select
                    variant="flushed"
                    value={timeRange}
                    onChange={(e) => setTimeRange(e.target.value)}
                  >
                    <option value="">Time</option>
                    <option value="today">Today</option>
                    <option value="yesterday">Yesterday</option>
                    <option value="currentWeek">Current Week</option>
                    <option value="currentMonth">Current Month</option>
                    <option value="currentTrimester">Current Trimester</option>
                    <option value="currentYear">Current Year</option>
                    <option value="lastYear">Last Year</option>
                  </Select>
                </Flex>
              </Flex>
            </Box>
          </Card>
          <Table
            variant="simple"
            color={textColor}
            style={{ marginTop: "10px" }}
          >
            <Thead>
              <Tr my=".8rem" pl="0px" color="gray.400">
                <Th borderColor={borderColor} color="gray.500">
                  Tag
                </Th>
                <Th pl="0px" borderColor={borderColor} color="gray.500">
                  Product { !isLoading &&  orderAlph == "asc" ?  <ArrowUpIcon boxSize={5} onClick={()=>clickOrderAlph()}/> : <ArrowDownIcon boxSize={5} onClick={()=>clickOrderAlph()}/> }
                </Th>
                <Th borderColor={borderColor} color="gray.500">
                  Distributor
                </Th>
                <Th borderColor={borderColor} color="gray.500">
                  Count { !isLoading &&  orderCount == "desc" ?  <ArrowUpIcon boxSize={5} onClick={()=>setOrderCount("asc")}/> : <ArrowDownIcon boxSize={5} onClick={()=>setOrderCount("desc")}/> }
                </Th>
                <Th borderColor={borderColor} color="gray.500">
                  View Scans
                </Th>
                <Th borderColor={borderColor} color="gray.500">
                  Flag
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {!isLoading &&
                scans.map((row, index, arr) => {
                  return (
                    <ScansTable
                      logo={""}
                      id={row.productId}
                      tag={row.tagId}
                      location={row.location}
                      key={index}
                      isLast={index === arr.length - 1}
                      date={row.createdAt}
                      selectScans={selectScans}
                      ipAddress={row.ipAddress}
                      distributor={row?.distributorId}
                      flag={row.flag}
                      product={
       row.product
                      }
                      distributorName={row?.distributorName}
                    />
                  );
                })}
            </Tbody>
          </Table>
          {isLoading && (
            <div
              style={{
                padding: "40px",
                alignItems: "center",
                justifyItems: "center",
                justifyContent: "center",
                gap: "20px",
                display: "flex",
              }}
            >
              <LoadingIcon />
              <Text>Fetching scans...</Text>
            </div>
          )}
          {scans.length == 0 && !isLoading && (
            <div
              style={{
                padding: "15px",
                alignItems: "center",
                justifyItems: "center",
                justifyContent: "center",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Text style={{ padding: "10px" }}>No scans found...</Text>
            </div>
          )}

          {scans.length > 0 && (
            <Pagination
              currentPage={currentPage}
              totalPages={scansCount / limit == 1 ? 1 : scansCount / limit + 1}
              onPageChange={handlePageChange}
            />
          )}
          {scans.length == 0 && currentPage != 1 && !isLoading && (
            <Pagination
              currentPage={currentPage}
              totalPages={scansCount / limit == 1 ? 1 : scansCount / limit + 1}
              onPageChange={handlePageChange}
            />
          )}

        </CardBody>
      </Card>
      <ScansModal
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        scan={scan}
      />
    </Flex>
  );
}

export default Scans;


function sortByField(data, field, order) {
  return data.sort((a, b) => {

      const valueA = a.product?.name ? parseMultilingualText(a.product.name).toLowerCase() : "z";
      const valueB = b.product?.name ? parseMultilingualText(b.product.name).toLowerCase() : "z";

      if (order === 'asc') {
          return valueA < valueB ? -1 : valueA > valueB ? 1 : 0;
      } else if (order === 'desc') {
          return valueA > valueB ? -1 : valueA < valueB ? 1 : 0;
      } else {
          throw new Error("Invalid order parameter. Use 'asc' or 'desc'.");
      }
  });
}